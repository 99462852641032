/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-fragments */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useActivation } from '@context/MobileOne';
import ArrowLeft from '@theme/assets/img/arrow_left.svg';
import './index.scss';

// eslint-disable-next-line no-unused-vars
export function Stepper({ currentStep, setCurrentStep, onBackPress, items }) {
  // Contexts
  const { t } = useStaticContent();
  const { tariffActivationForm, showBackButton, step2SubStep } = useActivation();

  // Hooks
  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, [currentStep]);

  return (
    <div className="primary-light-gradient">
      <div className="bs-stepper d-lg-block">
        {/* your steps here */}
        <div className="top-header fixed-top">
          <div className="container">
            <div className="bs-stepper-header" role="tablist">
              <div
                className="back-icon d-flex justify-content-between"
                id={`ap-back-button-${currentStep}`}>
                <div>
                  {showBackButton && (
                    <img aria-hidden src={ArrowLeft} alt="Leftarrow" onClick={onBackPress} />
                  )}
                </div>
                {currentStep >= 1 && tariffActivationForm.chosenTariffId !== 0 && (
                  <div className={currentStep >= 1 ? 'card-activation-select' : ''}>
                    <p>
                      {t('ek_ap_chosen_tariff')}{' '}
                      <span style={{ color: tariffActivationForm.tariffColor }}>
                        {tariffActivationForm.chosenTariffName}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="stepper">
                <div className="stepper-title-count">
                  {currentStep === 0 && <p>{t('ek_ap_activation_nr_header')}</p>}
                  {currentStep === 1 && <p>{t('ek_ap_tarif_header')}</p>}
                  {currentStep === 2 &&
                    (step2SubStep === 0 ? (
                      <p>{t('ek_reg_voucher_page-title')}</p>
                    ) : (
                      <p>{t('ek_ap_personal_h1')}</p>
                    ))}
                  {currentStep === 3 && <p>{t('ek_ap_password_header')}</p>}
                  {currentStep === 4 && <p>{t('ek_ap_overview_header')}</p>}
                  {currentStep === 5 && <p>{t('ek_reg_step6_topup_head_title')}</p>}
                  {currentStep === 6 && <p>{t('ek_ap_legitimation_header')}</p>}
                  <span>{currentStep + 1}/7</span>
                </div>
                <div className="stepper-progress">
                  <ul>
                    <ul>
                      <li className={`stepper-bg ${currentStep >= 0 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 1 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 2 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 3 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 4 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 5 ? 'active' : ''}`} />
                      <li className={`stepper-bg ${currentStep >= 6 ? 'active' : ''}`} />
                    </ul>
                  </ul>
                </div>
              </div>

              {items.map((step, stepIndex) => (
                <Fragment key={`stepper-header-${step.title}`}>
                  <div className={currentStep === stepIndex ? 'step active' : 'step'}>
                    <button
                      type="button"
                      className={`step-trigger ${stepIndex === 0 ? 'ps-0' : ''} ${
                        stepIndex === items.length - 1 ? 'pe-0' : ''
                      }`}
                      // role="tab"
                      // onClick={() => setCurrentStep(stepIndex)}
                    >
                      {/* <span className="bs-stepper-circle">{stepIndex + 1}</span> */}
                      {/* <span className="bs-stepper-label">{step.title}</span> */}
                    </button>
                  </div>
                  {stepIndex > -1 && stepIndex < items.length - 1 && <div />}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="activation-main">
          <div className="bs-stepper-content p-0">
            {/* your steps content here */}
            {items.map(
              (step, stepIndex) =>
                currentStep === stepIndex && (
                  <Fragment key={`stepper-content-${step.title}`}>{step.Component}</Fragment>
                )
            )}
          </div>
        </div>
      </div>
      {/* <div className="bs-stepper d-block d-lg-none"> */}
      {/* your steps here */}
      {/* <div className="bs-stepper-header" role="tablist">
          <div className="line" />
          <div className="line" />
          {items.map(
            (step, stepIndex) =>
              currentStep === stepIndex && (
                <Fragment key={`stepper-header-${step.title}`}>
                  <div className="step active">
                    <button
                      type="button"
                      className={`step-trigger ${stepIndex === 0 ? 'ps-0' : ''} ${
                        stepIndex === items.length - 1 ? 'pe-0' : ''
                      }`}
                      // role="tab"
                      onClick={() => setCurrentStep(stepIndex)}
                    >
                      <span className="bs-stepper-circle">{stepIndex + 1}</span>
                      <span className="bs-stepper-label">{step.title}</span>
                    </button>
                  </div>
                </Fragment>
              )
          )}
          <div className="line" />
          <div className="line" />
        </div> */}
      {/* your steps content here */}
      {/* <div className="bs-stepper-content p-0">
          {items.map(
            (step, stepIndex) =>
              currentStep === stepIndex && (
                <Fragment key={`stepper-content-${step.title}`}>{step.Component}</Fragment>
              )
          )}
        </div> */}
      {/* </div> */}
    </div>
  );
}

Stepper.propTypes = {
  currentStep: PropTypes.number,
  setCurrentStep: PropTypes.func,
  onBackPress: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      Component: PropTypes.node.isRequired
    })
  ).isRequired
};

Stepper.defaultProps = {
  currentStep: 0,
  setCurrentStep: () => {},
  onBackPress: () => {}
};

export default Stepper;
