import React from 'react';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { Button, Text } from '@core/Inputs';
import { appTextFieldLabelClass } from '@utils/globalConstant';
import SuccessImage from '@theme/assets/img/Isolationsmodus.svg';

export function VoucherCode() {
  // State
  const [isCodeValidated, setIsCodeValidated] = React.useState(false);

  // Context
  const {
    isLoading,
    voucherCode,
    voucherBenifit,
    voucherCodeValidations,
    voucherCodeSubmit,
    setStep2SubStep
  } = useActivation();
  const { t } = useStaticContent();

  return (
    <div className="container">
      {isCodeValidated ? (
        <div className="d-flex flex-column align-items-center">
          <img src={SuccessImage} alt="logo" />
          <h1
            style={{ marginTop: '36px', marginBottom: '36px' }}
            dangerouslySetInnerHTML={{
              __html: t('ek_reg_voucher_suc_hdl1').replace('<API>', voucherBenifit)
            }}
          />
          <h3
            style={{ marginBottom: '48px' }}
            dangerouslySetInnerHTML={{ __html: t('ek_reg_voucher_suc_txt1') }}
          />
          <Button
            variant="primary"
            onClick={() => {
              setStep2SubStep(1);
            }}>
            {t('ek_reg_voucher_suc_btn1')}
          </Button>
        </div>
      ) : (
        <div className="card activation-card mx-auto">
          <div className="card-body">
            <Formik
              initialValues={{ voucherCode }}
              validationSchema={voucherCodeValidations}
              onSubmit={async (value, { setFieldError }) => {
                const res = await voucherCodeSubmit(value);
                if (res) {
                  setIsCodeValidated(true);
                } else {
                  setFieldError('voucherCode', t('ek_global_api_voucher_code_err'));
                  return;
                }
              }}>
              {({
                values: { voucherCode },
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                errors
              }) => (
                <Form onSubmit={handleSubmit}>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t('ek_reg_voucher_hdl1')
                    }}
                  />
                  <div className="activation-form d-flex justify-content-between flex-column">
                    <div className="form-group">
                      <Text
                        type="text"
                        labelCustomClass={appTextFieldLabelClass.V1}
                        label={t('ek_reg_voucher_code_lbl1')}
                        id="voucherCode"
                        name="voucherCode"
                        value={voucherCode}
                        placeholder={t('ek_reg_voucher_code_plc')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.voucherCode && !errors.voucherCode}
                        isInvalid={!!touched.voucherCode && !!errors.voucherCode}
                        error={
                          touched.voucherCode && errors.voucherCode ? errors.voucherCode : null
                        }
                      />
                    </div>
                    <div className="">
                      <div className="d-flex justify-content-end">
                        <Button
                          isIcon
                          icon={<i className="bi bi-arrow-right" />}
                          variant="primary"
                          type="submit"
                          isLoading={isLoading}>
                          {t('ek_reg_voucher_btn1')}
                        </Button>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <h6>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setStep2SubStep(1);
                            }}>
                            {t('ek_reg_voucher_lnk1')}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}

export default VoucherCode;
