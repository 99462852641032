import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { Stepper } from '@part/Stepper';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appRoute } from '@utils/globalConstant';

import { PhoneNumberActivation } from './PhoneNumber';
import { ChooseTariff } from './ChooseTariff';
import { PersonalData } from './PersonalData';
import { LoginPassword } from './LoginPassword';
import { OverView } from './OverView';
import { Charging } from './Charging';
import { Legitimation } from './Legitimation';
import { ActivationWelcome } from './Welcome';
import { VoucherCode } from './VoucherCode';

export function Activation() {
  // Constants

  // States
  // const [isActivationClicked, setIsActivationClicked] = useState(false);

  // Contexts
  const {
    currentStep,
    setCurrentStep,
    getLookup,
    prevStep,
    phoneNumberActivationForm,
    setPhoneNumberActivationForm,
    tariffActivationForm,
    setTariffActivationForm,
    processToPaymentForm,
    setProcessToPaymentForm,
    isChargingClicked,
    setIsChargingClicked,
    bookableTariffs,
    isActivationClicked,
    setIsActivationClicked,
    isLoading: isActivationLoading,
    setEmailCodeError,
    setOtherAmount,
    ssoPaymentToken,
    step2SubStep,
    setStep2SubStep
  } = useActivation();
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions
  function onBackPressed() {
    if (currentStep === 0) {
      if (phoneNumberActivationForm.msisdn === '') {
        navigate(appRoute.LOGIN);
      } else if (
        phoneNumberActivationForm.msisdn !== '' &&
        phoneNumberActivationForm.email === ''
      ) {
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, msisdn: '', puk: '' });
      } else if (
        phoneNumberActivationForm.msisdn !== '' &&
        phoneNumberActivationForm.email !== '' &&
        phoneNumberActivationForm.emailCode === ''
      ) {
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, email: '' });
      } else if (
        phoneNumberActivationForm.msisdn !== '' &&
        phoneNumberActivationForm.emailCode !== ''
      ) {
        setPhoneNumberActivationForm({ ...phoneNumberActivationForm, email: '', emailCode: '' });
        setEmailCodeError(false);
      }
    } else if (currentStep === 1) {
      if (bookableTariffs.length === 1) {
        prevStep();
      } else if (tariffActivationForm.chosenTariffId !== 0 && bookableTariffs.length > 1) {
        setTariffActivationForm({
          chosenTariffId: 0,
          chosenTariffName: '',
          tariffColor: ''
        });
      } else {
        prevStep();
      }
    } else if (currentStep === 5) {
      // UNUSED  - was used in old autotopup journey
      if (processToPaymentForm.periodType !== '' && processToPaymentForm.productId !== '') {
        setProcessToPaymentForm({
          ...processToPaymentForm,
          productId: '',
          productType: '',
          otherAmount: ''
        });
        setOtherAmount('');
      } else if (processToPaymentForm.periodType !== '') {
        setProcessToPaymentForm({
          ...processToPaymentForm,
          periodType: ''
        });
      } else if (processToPaymentForm.periodType === '') {
        // console.log('currentStep', currentStep);
        setIsChargingClicked(!isChargingClicked);
      } else {
        prevStep();
      }
    } else if (currentStep === 2) {
      if (step2SubStep === 0) {
        prevStep();
      } else if (step2SubStep === 1) {
        setStep2SubStep(0);
      } else {
        prevStep();
      }
    } else {
      prevStep();
    }
  }

  // Hooks
  useEffect(() => {
    getLookup();
  }, []);

  useEffect(() => {
    if (isActivationLoading || isStaticContentLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isActivationLoading, isStaticContentLoading]);

  // Constants for Activation Steps
  const stepItems = [
    { title: t('ek_ap_activation_nr_header'), Component: <PhoneNumberActivation /> },
    { title: t('ek_ap_tarif_header'), Component: <ChooseTariff /> },
    {
      title: step2SubStep === 0 ? t('ek_reg_voucher_page-title') : t('ek_ap_personal_h1'),
      Component: step2SubStep === 0 ? <VoucherCode /> : <PersonalData />
    },
    { title: t('ek_ap_password_header'), Component: <LoginPassword /> },
    { title: t('ek_ap_overview_header'), Component: <OverView /> },
    { title: t('ek_ap_topup_header'), Component: <Charging /> },
    { title: t('ek_ap_legitimation_header'), Component: <Legitimation /> }
  ];
  return (
    <>
      <MetaTitle
        title={t('ek_activation_meta_title')}
        description={t('ek_activation_meta_description')}
        keywords={t('ek_activation_meta_keywords')}
      />
      {isActivationClicked ? (
        <div>
          <Stepper
            {...{
              currentStep,
              setCurrentStep,
              items: stepItems,
              onBackPress: onBackPressed
            }}
          />
        </div>
      ) : (
        <ActivationWelcome
          isActivationClicked={isActivationClicked}
          setIsActivationClicked={setIsActivationClicked}
        />
      )}
    </>
  );
}

export * from './Charging';
export * from './ChooseTariff';
export * from './Complete';
export * from './Legitimation';
export * from './LoginPassword';
export * from './OverView';
export * from './PaymentActivation';
export * from './PaymentFlow';
export * from './PersonalData';
export * from './PersonalInfo';
export * from './PhoneNumber';
export * from './ValidateSIM';
export * from './Welcome';

export default Activation;
