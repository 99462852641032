import React from 'react';
import PropTypes from 'prop-types';
import paypal from '@theme/assets/img/Pay_Paypal2.svg';
import amexLogo from '@theme/assets/img/american_express_logo_new.svg';
import MasterCard from '@theme/assets/img/visa_mastercard_logo_new.svg';
// import PayVisa from '@theme/assets/img/pay_visa_master_amex.svg';
import Sepa from '@theme/assets/img/sepa_large.png';
import PayDirect from '@theme/assets/img/giropay_logo_new.svg';
import Sofort from '@theme/assets/img/sofort_large.png';
import applepay from '@theme/assets/img/icon-applepay.svg';
import googlepay from '@theme/assets/img/icon-googlepay.svg';
import openbanking from '@theme/assets/img/icon-openbanking.svg';
import { appPaymentMethods } from '@utils/globalConstant';
import './style.scss';

export function PaymentMethod({ name, isSelected, onSelect }) {
  return (
    <>
      <div
        aria-hidden
        className={`card-inner ${isSelected ? 'selected' : ''}`}
        border="primary"
        onClick={() => onSelect(name)}>
        <div className="card-body-inner">
          {/* <CardTitle>Method Name - {name}</CardTitle> */}

          <div className="justify-content-md-center p-0">
            {/* {name} */}
            <div className="cards-icon d-flex justify-content-center align-content-between text-center">
              {name === appPaymentMethods.PAYPAL && (
                <img className="paypal-logo" src={paypal} alt="paypalLogo" />
              )}
              {name === appPaymentMethods.SOFORT && (
                <img className="paypal-logo" src={Sofort} alt="sofortLogo" />
              )}

              {name === appPaymentMethods.PAY_DIRECT && (
                <img className="pay-direct-logo" src={PayDirect} alt="payDirectLogo" />
              )}
              {name === appPaymentMethods.SEPA_DIRECT_DEBIT && (
                <img className="Sepa-logo" src={Sepa} alt="sepaLogo" />
              )}
              {name === appPaymentMethods.Apple_PAY && (
                <img className="payment-logo" src={applepay} alt="applePayLogo" />
              )}
              {name === appPaymentMethods.GOOGLE_PAY && (
                <img className="payment-logo" src={googlepay} alt="googlePayLogo" />
              )}
              {name === appPaymentMethods.OPEN_BANKING && (
                <img className="payment-logo" src={openbanking} alt="openBankingLogo" />
              )}
              {/* {name === appPaymentMethods.PAYPAL && <img src={paypal} alt="paypalLogo" />}
              {name === appPaymentMethods.PAYPAL && <img src={paypal} alt="paypalLogo" />} */}
              {name === appPaymentMethods.CREDIT_CARD && (
                <img className="credit-card p-2 h-100 w-100" src={MasterCard} alt="PayVisaLogo" />
              )}
              {name === appPaymentMethods.AMERICAN_EXPRESS && (
                <img className="credit-card p-2 h-100 w-100" src={amexLogo} alt="amexLogo" />
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}

PaymentMethod.propTypes = {
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func
};

PaymentMethod.defaultProps = {
  name: '',
  isSelected: false,
  onSelect: () => { }
};

export default PaymentMethod;
