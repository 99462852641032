/* eslint-disable no-bitwise */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
// import { appRoute as commonRoutes } from '@dom-digital-online-media/dom-app-config-sdk';

import moment from 'moment';
import momentTz from 'moment-timezone';

import {
  appRegex as _appRegex,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_FIELD_VALIDATION_TYPE,
  appStorage as AppStorage
} from '@dom-digital-online-media/dom-app-config-sdk';
import * as yup from 'yup';

// App modes
export const appEnvMode = {
  NODE_ENV_DEV: 'development',
  NODE_ENV_PROD: 'production',
  NODE_ENV_STAGE: 'stage'
};

export const headerStringRoute = {
  DASHBOARD: 'dashboard',
  TARIFF: 'tariff',
  CHARGES: 'charges',
  HELP_AND_SERVICE: 'help-service',
  ACCOUNT: 'account'
};

// Extend routes
export const appRoute = {
  // Public Routes
  // ...commonRoutes,
  TEST: '/test',
  HOME: '/home',
  ACTIVATION: '/activation',
  ACTIVATION_WELCOME: '/activation-welcome',
  NOT_FOUND: '/404',
  DEBUG: '/debug',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  LANDING_PAGE: '/',
  APP_REVIEW_REDIRECT: '/store-review',

  // Private Routes
  DASHBOARD: '/dashboard',
  BOOKABLE_DATA: '/dashboard/options',
  FORCE_PASSWORD_CHANGE: '/force-password-change',

  USER_ACCOUNT: '/account',
  PREFERENCES: '/account/preferences',
  ADVANTAGES: '/account/advantages',
  TRUE_BONUS: '/account/advantages/true-bonus',
  BIRTHDAY: '/account/advantages/birthday',
  INSIDE_BONUS: '/account/advantages/inside-bonus',
  ADVANTAGE_AUTOTOPUP: '/account/advantages/auto-topup',
  PROFILE: '/account/profile',
  CHANGE_USER_INFO: '/account/profile/change-user-info',
  CHANGE_PASSWORD: '/account/profile/change-password',
  CHANGE_HOTLINE: '/account/profile/change-hotline-password',
  HELP_AND_SERVICE: '/help-service',
  TARIFF_DETAILS: '/account/tariff-details',
  CONTACT: '/help-service/contact',
  EXPLAINER_VIDEO: '/help-service/explainer-video',
  FAQ: '/help-service/faq',
  LEGAL: '/help-service/legal',
  DOCUMENTS: '/help-service/legal/documents',
  PRIVACY_DOCS: '/help-service/legal/privacy-documents',
  IMPRINT: '/help-service/legal/imprint',
  FOOT_NOTES: '/help-service/legal/foot-notes',
  CANCEL_CONTRACT: '/help-service/cancel-contract',
  LOCK_UNLOCK_SIM: '/help-service/lock-unlock-sim',
  CHARGES: '/charges',
  TOPUP_HISTORY: '/charges/topup-history',
  VOUCHER_HISTORY: '/charges/voucher-history',
  RECHARGE_CREDIT: '/charges/recharge-credit',
  RECHARGE_VOUCHER: '/charges/recharge-credit/recharge-voucher',
  SET_AUTO_TOPUP: '/charges/recharge-credit/setup-auto-topup',
  SET_DIRECT_TOPUP: '/charges/recharge-credit/setup-direct-topup',
  PAYMENT_CALLBACK: '/payment',

  ESIM_TARIFF: '/esim-tariff',
  TARIFF_OPTION: '/tariff',
  TARIFF_CHANGE: (id) => (id ? `/tariff/change/${id}` : '/tariff/change/:tariffId'),

  OPTION_BOOK: (id) => (id ? `/dashboard/option/book/${id}` : '/dashboard/option/book/:optionId'),
  SPEEDON_BOOK: (id) =>
    id ? `/dashboard/speedon/book/${id}` : '/dashboard/speedon/book/:speedonId',
  PASSOFFER_BOOK: (passCode) =>
    passCode ? `/dashboard/passoffer/book/${passCode}` : '/dashboard/passoffer/book/:passCode',
  DATAPASS_BOOK: (id) =>
    id ? `/dashboard/datapass/book/${id}` : '/dashboard/datapass/book/:datapassId',
  ROAMING_BOOK: (id) =>
    id ? `/dashboard/roaming/book/${id}` : '/dashboard/roaming/book/:roamingId'
};
export const storageKeys = {
  // ...commonRoutes,
  CODE: 'code',
  SESSION_STATE: 'session_state',
  X_LOG_TAG: 'x_log_tag'
};

export const appStorage = {
  ...AppStorage,
  CART_NAME: 'cartName',
  ORDER_NUMBER: 'orderNumber',
  ACTIVATION_DATA: 'activationData',
  ACTIVATION_PERSONAL_DATA: 'activationPersonalData',
  ACTIVATION_MSISDN: 'activationMsisdn',
  POSTIDENT: 'postIdent',
  TARIFF_SHOWN_TIME: 'tarrifShownTime',
  PREFERENCE_SHOW_TIME: 'preferenceShowTime',
  NAVIGATE_TO: 'navigateTo',
  PAYMENT_REASON: 'paymentReason',
  TOPUP_TYPE: 'topupType',
  TOPUP_AMOUNT: 'topupAmount',
  MSISDN: 'msisdn'
};

// App alert types
export const appAlert = {
  DEFAUT_TIMEOUT: 4000,
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
  SUCCESS: 'success',
  ERROR: 'danger'
};

// App alert types
export const appDefaultAlertMessage = {
  SUCCESS: 'success',
  ERROR: 'error',
  ERROR_NOT_FOUND: 'Not able to found your last request',
  ERROR_UNAUTH: 'Please login to continue!',
  INFO: 'info',
  WARNING: 'warning'
};

export const appIcons = {
  HOME: 'Home',
  DASHBOARD: 'Dashboard',
  RADIO_ACTIVE: 'RadioActive'
};

export const appTextFieldLabelClass = {
  V1: 'v1',
  V2: 'v2'
};

export const appTextInputClass = {
  V1: 'v1',
  V2: 'v2'
};

export const appIconsPath = {
  HOME: '/assets/images/icons/home.svg',
  ALPHACOMM_LOGO: '/assets/images/logos/alphacomm-logo.svg',
};

export const appButtonType = {
  PRIMARY: 'btn btn-primary',
  SECONDARY: 'btn btn-secondary',
  DANGER: 'btn btn-danger',
  LINK: 'btn btn-link p-0'
};

export const formValidation = ({
  type = DEFAULT_FIELD_VALIDATION_TYPE.STRING, //  for which type of validation required
  required = false, // for for required validations
  minLength = false, // provide min length if required
  minLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MIN_LENGTH,
  maxLength = false, // provide min length if required
  maxLengthError = DEFAULT_ERROR_MESSAGE.fieldValidation.MAX_LENGTH,
  regex = '', // regex if you want to do matching validation
  errorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.REQUIRED, // for addtional sub types of valdation which can required messages other than required.
  validErrorMessage = DEFAULT_ERROR_MESSAGE.fieldValidation.INVALID
}) => {
  // Assign the yup schema
  // Default willl be considered as string
  let valid = {};
  switch (type) {
    case DEFAULT_FIELD_VALIDATION_TYPE.ARRAY:
      valid = yup.array();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.BOOLEAN:
      valid = yup.boolean();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.DATE:
      valid = yup.date();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.MIXED:
      valid = yup.mixed();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.NUMBER:
      valid = yup.number();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.OBJECT:
      valid = yup.object();
      break;
    case DEFAULT_FIELD_VALIDATION_TYPE.STRING:
      valid = yup.string();
      break;
    default:
      valid = yup.string();
      break;
  }

  // Check if required and set error message for it.
  if (required && typeof required === 'string') {
    valid = valid.required(required);
  }

  // Check if minimum length and set error message for it.
  if (minLength) {
    valid = valid.min(minLength, minLengthError);
  }

  // Check if maximum length and set error message for it.
  if (maxLength) {
    valid = valid.max(maxLength, maxLengthError);
  }

  // Check if type is email
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.EMAIL) {
    valid = valid.email(errorMessage);
  }

  // Check if type is url
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.URL) {
    valid = valid.url(errorMessage);
  }

  // Check if type is lowe_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.LOWER_CASE) {
    valid = valid.lowercase(errorMessage);
  }

  // Check if type is upper_case
  if (type === DEFAULT_FIELD_VALIDATION_TYPE.UPPER_CASE) {
    valid = valid.uppercase(errorMessage);
  }

  // Check if regex is passed throw invalid error message.
  if (regex) {
    valid = valid.matches(regex, validErrorMessage);
  }

  return valid;
};

export const inputValidation = {
  VALID: 'valid',
  INVALID: 'invalid',

  MIN_ERROR: 'min',
  MAX_ERROR: 'max',

  MIN_MAX_ERROR: 'minMax',

  UPPER_CASE_ERROR: 'uppercase',
  LOWER_CASE_ERROR: 'lowecase',

  NUMBER_ERROR: 'number',
  SPECIAL_CASE_ERROR: 'specific',

  MATCH: 'match'
};


export const appRegex = {
  ..._appRegex,
  // validateStringLength: /^[\x21-\xFF]{12,100}$/,
  // validateUpperCase: /(?=.*[A-Z])/,
  // validateLowerCase: /(?=.*[a-z])/,
  // validateNumbers: /(?=.*\d)/,
  // validateSpecialChar: /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/
  validatePwd:
    /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])|(?=.*[a-z])(?=.*[A-Z])(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-]))([\x21-\xFF]){12,100}$/,
  validatePwdStringLength: /^.{12,100}$/,
  validatePwdUpperOrLowerCase: /(?=.*[a-zA-Z])/,
  validatePwdNumbers: /(?=.*\d)/,
  validatePwdSpecialChar: /(?=.*[?!\"§$%&#_()\[\]{}@*;:,\\/<>|~+-])/
};


// String Length Validations
export function validateStringLength(string, min = 12, max = 64) {
  return `${string}`.length >= min && `${string}`.length <= max;
  // return (
  //   appRegex.validateString.test(string) && `${string}`.length >= min && `${string}`.length <= max
  // );
}

// String Minimum Length Validations
export function validateMinStringLength(string, min = 12) {
  return `${string}`.length >= min;
}

// UpperCase Validations
export function validateUpperCase(string) {
  return appRegex.validateUpperCase.test(string);
}

// LowerCase Validations
export function validateLowerCase(string) {
  return appRegex.validateLowerCase.test(string);
}

// Number Validations
export function validateNumber(string) {
  return appRegex.validateNumbers.test(string);
}

// Special Validations
export function validateSpecial(string) {
  return /(?=.*[!"§$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Mobile Special Validations
export function validateMobileSpecial(string) {
  return /(?=.*[!$%&#])/.test(string);
  // return appRegex.validateSpecial.test(string);
}

// Password Validation
export function validatePassword(values) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values.newPassword, 12, 20);
    const hasUpperCase = validateUpperCase(values.newPassword);
    const hasLowerCase = validateLowerCase(values.newPassword);
    const hasNumber = validateNumber(values.newPassword);
    const hasSpecial = validateSpecial(values.newPassword);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          [inputValidation.MATCH]: values.newPassword === values.confirmPassword
        }
      };
    }

    if (
      (values.newPassword || values.confirmPassword) &&
      values.newPassword !== values.confirmPassword
    ) {
      return { confirmPassword: 'match_error' };
    }

    return {};
  });
  return Object.assign(...errors);
  // let errors = {};
  // Object.keys(values).forEach((controlName) => {
  //   const string = values[controlName];

  //   if (!string) {
  //     errors = {
  //       ...errors,
  //       [controlName]: 'required'
  //     }
  //     return errors;
  //   }
  //   if (controlName === 'confirmPassword') {
  //     errors = {
  //       confirmPassword: values.newPassword === string ? false : "DO_NOT_MATCH"
  //     }
  //     return errors;
  //   }

  //   const hasLength = validateStringLength(string, 8, 20);
  //   const hasUpperCase = validateUpperCase(string);
  //   const hasLowerCase = validateLowerCase(string);
  //   const hasNumber = validateNumber(string);
  //   const hasSpecial = validateSpecial(string);
  //   if (!hasLength) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLength error"
  //     }
  //     return errors;
  //   }
  //   if (!hasUpperCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasUpperCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasLowerCase) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasLowerCase error"
  //     }
  //     return errors;
  //   }
  //   if (!hasNumber) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasNumber error"
  //     }
  //     return errors;
  //   }
  //   if (!hasSpecial) {
  //     errors = {
  //       ...errors,
  //       [controlName]: "hasSpecial error"
  //     }
  //     return errors;
  //   }
  //   console.log(values, errors, {
  //     hasLength,
  //     hasUpperCase,
  //     hasLowerCase,
  //     hasNumber,
  //     hasSpecial,
  //   })

  //   // errors = {
  //   //   ...errors,
  //   //   [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //   //     ? false // inputValidation.VALID
  //   //     : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //   //   [inputValidation.MIN_MAX_ERROR]: hasLength,
  //   //   [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //   //   [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //   //   [inputValidation.NUMBER_ERROR]: hasNumber,
  //   //   [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   // }
  //   return errors;
  // })
  // return errors;
  // Confirm password validation
  // if (controlName === 'confirmPassword') {
  //   return {
  //     valid:
  //       password && string && password === string ? inputValidation.VALID : inputValidation.INVALID,
  //   };
  // }

  // const hasLength = validateStringLength(string, 8, 20);
  // const hasUpperCase = validateUpperCase(string);
  // const hasLowerCase = validateLowerCase(string);
  // const hasNumber = validateNumber(string);
  // const hasSpecial = validateSpecial(string);

  // return {
  //   error: {
  //     [controlName]: hasLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecial
  //       ? false // inputValidation.VALID
  //       : hasLength || hasUpperCase || hasLowerCase || hasNumber || hasSpecial,

  //     [inputValidation.MIN_MAX_ERROR]: hasLength,
  //     [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //     [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //     [inputValidation.NUMBER_ERROR]: hasNumber,
  //     [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //   }
  // };
}

// Password Validation
export function validatePasswordWithKeys(values, firstPasswordKey, confirmPasswordKey) {
  // string, controlName, password

  const errors = Object.keys(values).map((controlName) => {
    const string = values[controlName];
    if (!string) {
      return {
        [controlName]: 'required'
      };
    }

    const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
    const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
    const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
    const hasNumber = validateNumber(values[firstPasswordKey]);
    const hasSpecial = validateSpecial(values[firstPasswordKey]);

    if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
      return {
        otherErrors: {
          [inputValidation.MIN_MAX_ERROR]: hasLength,
          [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
          [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
          [inputValidation.NUMBER_ERROR]: hasNumber,
          [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
          [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
        }
      };
    }

    if (
      (values[firstPasswordKey] || values[confirmPasswordKey]) &&
      values[firstPasswordKey] !== values[confirmPasswordKey]
    ) {
      return { [confirmPasswordKey]: 'match_error' };
    }

    return {};
  });
  return Object.assign(...errors);
}
export const gettingNumberForGerman = (number = '') => {
  let num = number;
  // eslint-disable-next-line eqeqeq
  if (num.charAt(0) == 0) {
    num = num.substring(1);
  }
  return `+49${num}`;
};

export const appAutoTopUpType = {
  UNIQUE: 'UNIQUE',
  AUTOMATIC: 'AUTOMATIC'
};

export const AutoTopUpFor = {
  ACTIVATION: 'ACTIVATION',
  LOGIN: 'LOGIN'
};

export const appTopUpType = {
  UNIQUE: 'UNIQUE',
  DIRECT: 'DIRECT',
  AUTOMATIC: 'AUTOMATIC'
};

export const appTopUpTo = {
  SELF: 'SELF',
  OTHER: 'OTHER'
};

export const appAutoTopUpPeriodType = {
  LOW_BALANCE: 'LOW_BALANCE',
  ONCE_PER_MONTH: 'ONCE_PER_MONTH',
  RATE: 'FIXED_RATE'
};

export const appPaymentCertainAmount = [5, 10, 15, 20, 50];
export const appPaymentCertainDay = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28
];

export const appPaymentTokenStatus = {
  UNCONFIRMED: 'UNCONFIRMED_PAYMENT_TOKEN'
};

export const appPaymentOrderStatus = {
  CREATION_PENDING: 'creationPending',
  FAILED: 'failed',
  SUCCESS: 'success',
};

export const appPaymentProductType = {
  // Currently using
  RECURRING: 'RECURRING',
  LOW_BALANCE: 'LOW_BALANCE',
  DIRECT: 'DIRECT',

  // Extended
  ALL: 'ALL',
  PREPAID: 'PREPAID',
  TOPUP: 'TOPUP',
  CASH_CODE: 'CASH_CODE',
  VOUCHER: 'VOUCHER'
};

export const appCustomCardClass = {
  PAGE_CARD: 'page-card', // 734px
  ACTIVATION_CARD: 'activation-card', // 412px
  USAGE_CARD: 'usage-card',
  TARIFF_CARD: 'tariff-card',
  ACTIVE_TARIFF_CARD: 'active-tariff-card',
  PROFILE_CARD: 'profile-card',
  OPTION_CARD: 'option-card' // 412px
};

export const appOptionsGroupIDs = {
  TARIFF_OPTION: [350],
  DATA_OPTIONS: [115, 352],
  DAYFLATS: [151],
  SPEED_ONS_PASSES: [152],
  ROAMING: {
    LANDER_ZONE_1: [153],
    LANDER_ZONE_2: [154],
    LANDER_ZONE_3: [155]
  },
  OTHER: [156] // Sprach-Optionen
};

// MO Customer Tariff Options & Usage Status
// https://rm3.dom.de/projects/cnet/wiki/Customer_Tariff_Options_&_Usage_Status

export const appCustomerStatus = {
  NEW: 0,
  ACTIVE: 1,
  CANCELLED: 2,
  NEW_INREALISATION: 3,
  INACTIVE: 4,
  IN_CANCELLATION: 5,
  MNP2: 6,
  MNP: 7,
  MARKED_DELETED: 8
};

export const appTariffStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18
};
// Find Tariff Status Key
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const getTariffStatusKey = (statusId) => getKeyByValue(appTariffStatus, statusId);

export const appOptionStatus = {
  ACTIVATION_PENDING: 0,
  ACTIVE: 1,
  REJECTED: 2,
  LOCKED: 3,
  LOCK_REQUESTED: 4,
  DEACTIVATION_REQUESTED: 5,
  PAUSED: 6,
  TERMINATION_REQUESTED: 7,
  UNLOCK_REQUESTED: 8,
  TERMINATED: 9,
  ACTIVATION_REQUESTED: 10,
  SENT: 11,
  CANCELLATION_REQUESTED: 12,
  CANCELLED: 13,
  NOT_AVAILABLE: 14,
  DEFECT: 15,
  EXPORTED: 16,
  IN_CHANGE: 17,
  UNKNOWN: 18
};

export const appUsageType = {
  VOICE: 'VOICE',
  DATA: 'DATA',
  SMS: 'SMS',
  DATA_UNL: 'DATA_UNL',
  VOICE_UNL: 'VOICE_UNL',
  SMS_UNL: 'SMS_UNL',
  MEASUREMENT: 'MEASUREMENT'
};

export const appUsageMeasurementsUnits = {
  MB: 'MB',
  GB: 'GB',
  MIN: 'MIN',
  SMS: 'SMS'
};

// TDM API response type
export const internetDataUnit = {
  GB: 'GB',
  MB: 'MB',
  KB: 'KB'
};

// Method to get data in GB from required unit
export const formatBytes = (data, units) => {
  const marker = 1024; // Change to 1000 if required
  const decimal = 1; // Change as required
  if (units === internetDataUnit.KB) {
    return (data / (marker * marker)).toFixed(decimal);
  }
  if (units === internetDataUnit.MB) {
    return (data / marker).toFixed(decimal);
  }
  if (units === internetDataUnit.GB) {
    return data;
  }
  return data;
};

export const getMaximumValue = ({
  initialValue,
  remainingValue,
  initialUnit,
  remainingUnit,
  ...restData
}) => {
  let maxValue = restData != null && initialValue != null ? initialValue : 0;
  let currentValue = restData != null && remainingValue != null ? remainingValue : 0;

  if (restData != null && initialValue != null && remainingValue != null) {
    if (initialUnit.includes(internetDataUnit.GB) && remainingUnit.includes(internetDataUnit.GB)) {
      maxValue = parseFloat(maxValue);
      currentValue = parseFloat(currentValue);
    } else if (
      initialUnit.includes(internetDataUnit.MB) &&
      remainingUnit.includes(internetDataUnit.MB)
    ) {
      maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
      currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (initialUnit.includes(internetDataUnit.GB)) {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.GB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.MB);
      } else {
        maxValue = formatBytes(parseFloat(maxValue), internetDataUnit.MB);
        currentValue = formatBytes(parseFloat(currentValue), internetDataUnit.GB);
      }
    }
  }

  return {
    maximumValue: maxValue,
    currentValue
  };
};

// temporary fix for already fixed issue in dom-mo-sdk v0.1.89 but we can't use this version because of the breaking change
function formatNumber(number) {
  // Replace all non-numeric characters except the last one
  return number.replace(/(?!\.\d*$)[^\d]/g, '');
}

export const getMaxValue = (data) => {
  let maxValue = data != null && data.initialValue != null ? data.initialValue : 0;
  let currValue = data != null && data.remainingValue != null ? data.remainingValue : 0;

  if (data != null && data.initialValue != null && data.remainingValue != null) {
    if (data.type.includes('DATA')) {
      data.remainingValue = formatNumber(data.remainingValue);
    }
    if (data.initialUnit.includes('GB') && data.remainingUnit.includes('GB')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.type.includes('VOICE') || data.type.includes('VOICE')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.type.includes('SMS') || data.type.includes('SMS')) {
      maxValue = parseFloat(maxValue);
      currValue = parseFloat(currValue);
    } else if (data.initialUnit.includes('MB') && data.remainingUnit.includes('MB')) {
      maxValue = formatBytes(parseFloat(maxValue), 'MB');
      currValue = formatBytes(parseFloat(currValue), 'MB');
    } else if (data.initialUnit.includes('GB')) {
      maxValue = formatBytes(parseFloat(maxValue), 'GB');
      currValue = formatBytes(parseFloat(currValue), 'MB');
    } else {
      maxValue = formatBytes(parseFloat(maxValue), 'MB');
      currValue = formatBytes(parseFloat(currValue), 'GB');
    }
  }

  return {
    maximumValue: maxValue,
    currValue
  };
};


// Taken over from edeka-app implementation (2024-12-11)
// Calculating the date difference 
export const dateTimeDifferenceCalculator = (firstDate, secondDate) => {
  let date2 = moment(firstDate, 'DD.MM.YYYY HH:mm:ss (MEZ)');
  let date1 = !secondDate ? moment() : moment(secondDate, 'DD.MM.YYYY  HH:mm:ss (Z)');

  const dateMoment = momentTz.tz(firstDate, 'DD.MM.YYYY HH:mm:ss', 'Europe/Berlin');

  // Calculate the difference in milliseconds
  let differenceInMillis = dateMoment.diff(date1);

  // Convert milliseconds into days, hours, and minutes
  let duration = moment.duration(differenceInMillis);

  let days = Math.floor(duration.asDays());
  let hours = Math.floor(duration.hours());
  let minutes = Math.floor(duration.minutes());

  let leftTime = {}

  leftTime ={
    value: days,
    hoursValue: hours,
    minutesValue: minutes,
  }

  return leftTime
}


export function validateEmail(string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    string
  );
}

// prefix for number validations
export function validateNumberPrefix(string) {
  return /^.{1,6}$/.test(string);
}

// prefix for number validations
export function validatePhoneNumber(string) {
  return /^[0-9]{4,15}$/.test(string);
}

export const DAYFLAT_TITLE = 'DayFlat unlimited';
export const DAYFLAT_VALUE = '10.240';
export const appPassTypeID = {
  DAY_FLAT_UNLIMITED: 102
};

export const counterTDMArray = [
  {
    id: 1,
    title: 'ek_dashboard_data-volume',
    counterType: appUsageType.DATA
  },
  {
    id: 2,
    title: 'ek_dashboard_minutes',
    counterType: appUsageType.VOICE
  },
  {
    id: 3,
    title: 'ek_dashboard_sms',
    counterType: appUsageType.SMS
  }
];

export const validateYear = {
  DEFAULT_DOB_VALIDATION_YEAR: 16
};

export const displayPrice = (price) => {
  const amount = price;
  if (amount) {
    return parseFloat(amount).toFixed(2);
  }
  return 0;
};

export const appBreakPoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
};

// eslint-disable-next-line consistent-return
export const findBreakPoint = (screenSize) => {
  if (screenSize >= appBreakPoints.xxl) {
    return 'xxl';
  }
  if (screenSize >= appBreakPoints.xl) {
    return 'xl';
  }
  if (screenSize >= appBreakPoints.lg) {
    return 'lg';
  }
  if (screenSize >= appBreakPoints.md) {
    return 'md';
  }
  if (screenSize >= appBreakPoints.sm) {
    return 'sm';
  }
  if (screenSize >= appBreakPoints.xs) {
    return 'xs';
  }
};

export const validateDOB = () =>
  `${new Date().getFullYear() - validateYear.DEFAULT_DOB_VALIDATION_YEAR}/${
    new Date().getMonth() + 1
  }/${new Date().getDate()}`;

export const validateDOBde = () =>
  `${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}.${
    new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : new Date().getMonth() + 1
  }.${new Date().getFullYear() - validateYear.DEFAULT_DOB_VALIDATION_YEAR}`;

export const progressPrecentageValue = (minValue, maxValue) => (minValue / maxValue) * 100;

export const appTariffs = {
  // Monthly
  TALK: 1803,
  KOMBI_S: 1812,
  KOMBI_M: 1813,
  KOMBI_L: 1814,
  KOMBI_XL: 1815,
  KOMBI_MAX: 1810,
  JAHRESTARIF_PREMIUM: 1816,
  JAHRESTARIF_START: 1811,
  TALK_JAHRESTARIF_START: 1817
};

// Payment method's  type
export const appPaymentMethods = {
  PAYPAL: 'paypal',
  SOFORT: 'sofort',
  CREDIT_CARD: 'creditcard',
  PAY_DIRECT: 'paydirekt',
  SEPA_DIRECT_DEBIT: 'sepa-direct-debit',
  AMERICAN_EXPRESS: 'american-express',
  Apple_PAY: 'applepay',
  GOOGLE_PAY: 'googlepay',
  OPEN_BANKING: 'openbanking',
};

export const faqCategory = {
  THREE_G: '3G',
  GENERAL: 'Allgemeines',
  UNLOCK: 'Freischalten',
  CUSTOMER_PORTAL: 'Kundenportal',
  TARIFE: 'Tarife',
  ESIM: 'eSIM'
};

export const birthdayBonus = {
  AVAILABLE: 'NONE'
};

export const appTokenStatus = {
  CONFIRMED: 'confirmed',
  PENDING: 'pending',
  CANCELLED: 'cancelled'
};

export const deviceRegex = {
  ISIOS: /iPhone|iPad|iPod/i,
  ISANDRIOD: /Android/i
};

export function generateUUID() {
  var d = new Date().getTime();
  var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function generateRandomValue() {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomValue = '';

  for (let i = 0; i < 3; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomValue += characters.charAt(randomIndex);
  }

  return randomValue;
}

export const getOptionLegalTextCmsKey = (passCodeOrId, passCodeSettings) => {
  // handle case of null or undefined
  if (!passCodeSettings || !passCodeSettings.additionalInfo) {
    return '';
  }

  const { additionalInfo } = passCodeSettings;
  if (additionalInfo[passCodeOrId]) {
    return additionalInfo[passCodeOrId].legalText;
  } else {
    return 'ek_tarif_options_order_text1'; // fallback key
  }
};

export default {
  appRoute,
  storageKeys,
  appAlert,
  appDefaultAlertMessage,
  counterTDMArray,
  gettingNumberForGerman,

  validateDOB,
  progressPrecentageValue,
  getMaximumValue,
  getMaxValue,
  getOptionLegalTextCmsKey
};
